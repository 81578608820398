import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import Layout from "./layout";
import Hero from "./HeroBanner";
import Container from "./container";
import * as styles from "./functionPageLayout.module.css";
import { FaArrowCircleRight } from "react-icons/fa";
import heroImage from "../images/empireo.jpg";

const FunctionPageLayout = ({ title, headline, noDemo, children }) => {
  const data = useStaticQuery(graphql`
    {
      heroBg1: file(relativePath: { eq: "empireo.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  `);
  return (
    <Layout>
      <Hero heroImage={data.heroBg1} height="280px" justifyContent="center">
        <h1>{title}</h1>
        <br />
        <h2>{headline}</h2>
      </Hero>
      <Container>
        <div className={styles.contentWrapper}>{children}</div>
      </Container>
    </Layout>
  );
};

export default FunctionPageLayout;
