import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";

const Tile = ({ children, to }) => {
  return <TileCard>{children}</TileCard>;
};

const TileCard = styled(Link)`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 20px;
  margin: 20px;
  text-align: center;
  background: hsl(0, 0%, 92.9%);
  transition: background-color 200ms ease-in;
  color: black;

  :hover {
    background: hsl(0, 0%, 92.9%);
    text-decoration: underline;
  }

  @media (max-width: 1180px) {
    min-width: 90%;
    margin: 20px;
  }
`;

export default Tile;
