import styled from "@emotion/styled";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React from "react";

const Hero = ({
  children,
  heroImage,
  maxHeight,
  justifyContent,
  imageHeight,
  height,
}) => {
  const image = getImage(heroImage);
  return (
    <Container
      className="hero__container"
      height={height}
      maxHeight={maxHeight}
    >
      <BgImage image={image} imageHeight={imageHeight} loading="eager" />
      <Overlay justifyContent={justifyContent}>{children}</Overlay>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  height: ${(props) => (props.height ? props.height : "280px")};
  max-height: ${(props) => props.maxHeight};
  position: relative;
  align-items: center;
  color: hsl(40, 23%, 97%);
  overflow: hidden;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));

  p {
    font-size: 22px;
    color: inherit;
  }
`;

const Overlay = styled.div`
  width: 80%;
  text-align: center;
  margin: 0px auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: ${(props) => props.justifyContent || "space-between"};
  align-items: center;
  text-align: center;
  flex-direction: column;
`;

const BgImage = styled(GatsbyImage)`
  width: 100%;
  z-index: -1;
  height: ${(props) => (props.imageHeight ? props.imageHeight : "100%")};
`;

export default Hero;
